// CoreUI Light Mode Variables
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$original-gray: #ededed !default;
$black: #000 !default;

$input-border: #cccccc !default;
$body-bg: $white !default;
$body-color: $gray-900 !default;

$primary: #007bff !default;
$secondary: $gray-600 !default;
$success: #28a745 !default;
$info: #17a2b8 !default;
$warning: #ffc107 !default;
$danger: #dc3545 !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;

// Theme Colors Map
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
) !default;

// Typography
// $font-family-base: "Open Sans", sans-serif !default;
// $font-weight-base: 500 !default;
