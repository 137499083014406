// Font
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

@import "variables";
// Import CoreUI styles
@import "~@coreui/coreui-pro/scss/coreui.scss";

:root {
  --cui-white: #{$white};
  --cui-gray-100: #{$gray-100};
  --cui-gray-200: #{$original-gray};
  --cui-gray-300: #{$gray-300};
  --cui-body-bg: #{$body-bg};
  --cui-body-color: #{$body-color};
  --cui-primary: #{$primary};
  --cui-input-border: #{$input-border};
}

// Dark mode overrides
[data-coreui-theme="dark"] {
  @import "variables-dark";
  --cui-white: #{$white-dark};
  --cui-gray-100: #{$white-dark};
  --cui-gray-200: #{$gray-200-dark};
  --cui-gray-300: #{$gray-300-dark};
  --cui-body-bg: #{$body-bg-dark};
  --cui-body-color: #{$body-color-dark};
  --cui-primary: #{$primary-dark};
  --cui-input-border: #{$input-border-dark};
}

// Some temp fixes
@import "fixes";

// If you want to add something do it here
@import "custom";
@import "_autoComplete";
